// @file Landside navigation store
import { trackPageview } from '@@/bits/analytics'
import { __ } from '@@/bits/intl'
import { getPathnameItems } from '@@/bits/location'
import { PricingPlansExperimentVariant } from '@@/enums'
import { OzBaseButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
import type { User, WallGalleryTemplate } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum LandsidePage {
  Home = 'home',
  Product = 'product',
  Subscriptions = 'subscriptions',
  Templates = 'templates',
  Help = 'help',
  Careers = 'careers',
  WhatsNew = 'whatsnew',
  Pricing = 'pricing',
  DynamicLandingPage = 'dynamic_landing_page',
  Board = 'board',
  Sandbox = 'sandbox',
  Education = 'education',
}

export enum ProductPageTab {
  HOW_IT_WORKS = 'howitworks',
  EDUCATION = 'education',
}

export enum TemplatesPageTab {
  GENERAL = 'general',
  EDUCATION = 'education',
  BUSINESS = 'business',
}

// Referenced from app/controllers/landside_controller.rb
const landsidePageTitles = {
  [LandsidePage.Home]: __('Creative tools for visual thinkers and learners'),
  [LandsidePage.Product]: __('Product'),
  [LandsidePage.Templates]: __('Templates'),
  [LandsidePage.Subscriptions]: __('Subscriptions'),
  [LandsidePage.Pricing]: __('Pricing'),
  [LandsidePage.DynamicLandingPage]: __('Creative tools for visual thinkers and learners'),
  [LandsidePage.Sandbox]: __('Sandbox'),
  [LandsidePage.Education]: __('Education'),
  [LandsidePage.Board]: __('Board'),
}

const productPageTabTitles = {
  [ProductPageTab.EDUCATION]: __('Padlet - Education'),
  [ProductPageTab.HOW_IT_WORKS]: __('Padlet - How it works'),
}

const templatesPageTabTitles = {
  [TemplatesPageTab.GENERAL]: __('Templates'),
  [TemplatesPageTab.EDUCATION]: __('Templates for education'),
  [TemplatesPageTab.BUSINESS]: __('Templates for business'),
}

const templatesPageTabDescriptions = {
  [TemplatesPageTab.GENERAL]: __(
    'Browse over 250 beautiful, free templates. Save time with easy-to-use boards designed for education, business, and beyond.',
  ),
  [TemplatesPageTab.EDUCATION]: __('Step-by-step guides for creating padlets for education.'),
  [TemplatesPageTab.BUSINESS]: __('Step-by-step guides for creating padlets for business.'),
}

export const useLandsideNavigationStore = defineStore('landsideNavigation', () => {
  // State

  const currentPage = ref(LandsidePage.Home)
  const isUserLoggedIn = ref(false)
  const currentUser = ref<User | null>(null)
  const productPageTab = ref<ProductPageTab>(ProductPageTab.HOW_IT_WORKS)
  const pricingPlansExperimentVariant = ref<PricingPlansExperimentVariant>(PricingPlansExperimentVariant.Control)

  const excludedButtonTitle = ref<string | null>(null)

  // Getters
  const landsidePages = computed(() => {
    return [
      {
        key: LandsidePage.Home,
        text: __('Home'),
        href: '/',
        hrefMode: isUserLoggedIn.value ? OzBaseButtonHrefMode.Default : OzBaseButtonHrefMode.HistoryPushState,
      },
      {
        key: LandsidePage.Board,
        text: __('Board'),
        href: '/site/board',
        hrefMode: OzBaseButtonHrefMode.HistoryPushState,
      },
      {
        key: LandsidePage.Sandbox,
        text: __('Sandbox'),
        href: '/site/sandbox',
        hrefMode: OzBaseButtonHrefMode.HistoryPushState,
      },
      {
        key: LandsidePage.Subscriptions,
        text: excludedButtonTitle.value === LandsidePage.Subscriptions ? __('Pricing') : __('Subscriptions'),
        href: '/site/subscriptions',
        hrefMode: OzBaseButtonHrefMode.HistoryPushState,
      },
      {
        key: LandsidePage.Templates,
        text: __('Templates'),
        href: '/site/templates',
        hrefMode: OzBaseButtonHrefMode.HistoryPushState,
      },
      {
        key: LandsidePage.Education,
        text: __('Education'),
        href: '/site/education',
        hrefMode: OzBaseButtonHrefMode.HistoryPushState,
      },
    ]
  })

  function initializeState(data: {
    isUserLoggedIn: boolean
    currentUser: User
    pricingPlansExperimentVariant: PricingPlansExperimentVariant
  }): void {
    isUserLoggedIn.value = data.isUserLoggedIn ?? isUserLoggedIn.value
    currentUser.value = data.currentUser ?? currentUser.value
    pricingPlansExperimentVariant.value = data.pricingPlansExperimentVariant ?? pricingPlansExperimentVariant.value

    const excludedButtonTitleInStorage = localStorage.getItem('excludedButtonTitle')
    if (excludedButtonTitleInStorage === null) {
      const chosenPricingText = Math.random() < 0.5 ? LandsidePage.Pricing : LandsidePage.Subscriptions
      excludedButtonTitle.value = chosenPricingText
      localStorage.setItem('excludedButtonTitle', chosenPricingText)
    } else {
      excludedButtonTitle.value = excludedButtonTitleInStorage
    }
  }

  function setCurrentPage(page: LandsidePage): void {
    // If user is logged in, they should be directed to /dashboard and we want to prevent the UI from changing
    if (page === LandsidePage.Home && isUserLoggedIn.value) return
    if (page === LandsidePage.Help) return // Prevent the UI from changing since Help is an external url
    if (page === LandsidePage.Careers) return // Prevent the UI from changing since Careers is an external url
    if (page === LandsidePage.WhatsNew) return // Prevent the UI from changing since WhatsNew is an external url

    const userFocusedElement = document.activeElement as HTMLElement
    const pricingSectionElement = document.querySelector('[data-testid="landside-subscription-pricing-section"')

    currentPage.value = page
    trackPageview('Homepage', page.charAt(0).toUpperCase() + page.slice(1))

    // if user tries to navigate to subscriptions page, it should only reload if user is clicking at the footer
    if (page === LandsidePage.Subscriptions && pricingSectionElement?.contains(userFocusedElement) === false) {
      location.reload()
    }
  }

  function scrollToTop(): void {
    window.scrollTo({
      top: 0,
    })
  }

  function setCurrentPageWithSideEffects(page: LandsidePage): void {
    setCurrentPage(page)

    // Side effects
    scrollToTop()

    // Update document title
    document.title = landsidePageTitles[page]
  }

  function setProductPageTab(tab: ProductPageTab): void {
    productPageTab.value = Object.values(ProductPageTab).includes(tab) ? tab : ProductPageTab.HOW_IT_WORKS
  }

  function setProductPageTabWithSideEffects(tab: ProductPageTab): void {
    setProductPageTab(tab)

    // Side effects
    scrollToTop()

    // Update document title
    document.title = productPageTabTitles[tab]
  }

  function updateTemplatePageTabMetadata(tab: TemplatesPageTab): void {
    // Update document title
    document.title = templatesPageTabTitles[tab]
    // Update document description
    document.querySelector('meta[name="description"]')?.setAttribute('content', templatesPageTabDescriptions[tab])
  }

  function updateTemplatePageMetadata(wallTemplate: WallGalleryTemplate): void {
    // Update document title
    document.title = __('%{wallTemplateTitle} - Padlet', { wallTemplateTitle: wallTemplate.title })
    // Update document description
    document.querySelector('meta[name="description"]')?.setAttribute('content', wallTemplate.description ?? '')
  }

  function setTemplatesPageTabWithSideEffects(tab: TemplatesPageTab): void {
    // Side effects
    scrollToTop()

    updateTemplatePageTabMetadata(tab)
  }

  function switchPageToUrlParams(): void {
    // The first element in getPathnameItems for Landside returns 'site'
    const pathNameItems = getPathnameItems().filter((pathNameItem) => pathNameItem !== 'site')
    let landsidePage: LandsidePage
    // If path represents a dynamic landing page, we set the landsidePage to DynamicLandingPage
    if (pathNameItems[0] === 'product' && pathNameItems[1] === 'features') {
      landsidePage = LandsidePage.DynamicLandingPage
    } else {
      landsidePage = pathNameItems[0] as LandsidePage
    }
    if (Object.values(LandsidePage).includes(landsidePage)) {
      setCurrentPageWithSideEffects(landsidePage)

      if (landsidePage === LandsidePage.Product) {
        const tab = pathNameItems[1] as ProductPageTab
        setProductPageTab(tab)
        return
      }

      return
    }

    // Fallthrough when landsidePage is not a valid landside page,
    // we set the homepage as the current page
    setCurrentPageWithSideEffects(LandsidePage.Home)
  }

  return {
    // State
    currentPage,
    productPageTab,
    isUserLoggedIn,
    currentUser,
    pricingPlansExperimentVariant,

    // Getters
    landsidePages,

    // Actions
    initializeState,
    scrollToTop,
    setCurrentPage,
    setCurrentPageWithSideEffects,
    setProductPageTab,
    setProductPageTabWithSideEffects,
    setTemplatesPageTabWithSideEffects,
    updateTemplatePageTabMetadata,
    updateTemplatePageMetadata,
    switchPageToUrlParams,
  }
})
